import React, { useState } from "react"
// import StoreContext from "../context/StoreContext"
import ProductDetails from "../components/productDetails"
import VariantButton from "../components/variantButton"
import UpsellProduct from "../components/upsellProduct"
// import TryButton from "../components/tryButton"
import ShopifyButton from "../components/shopifyButton"
import Img from "gatsby-image"
import "../styles/pdp.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import CONSTANTS from "../constants"
import ReactPixel from "react-facebook-pixel"
import utils from "../utils"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ProductTemplate = ({ pageContext }) => {
  const { contentfulProduct, contentfulProductColors, storeInfo } = pageContext
  const productTitle = getShopifyProductTitle()
  const upsellProductTitle = getUpsellShopifyProductTitle()
  const defaultVariant = getDefaultVariant()
  const [active, setActive] = useState(defaultVariant)
  const [upsellProduct, setUpsellProduct] = useState({})
  const [addUpsell, setAddUpsell] = useState(false)

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  // const {
  //   store: { checkout },
  //   addVariantToCart,
  // } = useContext(StoreContext)

  function getDefaultVariant() {
    if (
      contentfulProduct &&
      contentfulProduct.shopifyProduct &&
      contentfulProduct.shopifyProduct.variants.edges
    ) {
      return contentfulProduct.shopifyProduct.variants.edges[0].node
    }
    return null
  }

  function getShopifyProductTitle() {
    if (contentfulProduct && contentfulProduct.shopifyProduct) {
      return contentfulProduct.shopifyProduct.title
    }
    return null
  }
  function getUpsellShopifyProductTitle() {
    if (
      contentfulProduct &&
      contentfulProduct.shopifyProduct &&
      contentfulProduct.upsell
    ) {
      return contentfulProduct.upsell.shopifyProduct.title
    }
    return null
  }

  function trackCartPixel(productTitle, active) {
    ReactPixel.track("AddToCart", {
      content_name: `${productTitle}`,
      content_ids: `${decodeId(active.id)}`,
      content_type: "product",
      content_variant_title: `${active.title}`,
      value: `${active.price}`,
      currency: CONSTANTS.CURRENCY,
    })
  }

  const decodeId = id => {
    id = atob(id)
    id = id.substring(id.lastIndexOf("/") + 1, id.length)
    return id
  }

  // const addProductToCart = async e => {
  //   e.preventDefault()
  //   if (active.availableForSale) {
  //     const customAttributes = utils.buildCustomAttributes({
  //       tags: contentfulProduct.shopifyProduct.tags,
  //     })
  //     await addVariantToCart(active.id, 1, customAttributes)
  //
  //     trackCartPixel(productTitle, active)
  //     window.open(checkout.webUrl)
  //   }
  // }
  const addProductToShopifyCart = async e => {
    e.preventDefault()
    if (active.availableForSale) {
      let decodedVariantId = decodeId(active.id)
      const customAttributes = utils.buildTrialPropertyString({
        tags: contentfulProduct.shopifyProduct.tags,
      })
      if (!addUpsell) {
        trackCartPixel(productTitle, active)

        window.location.href =
          CONSTANTS.CART_URL + "/add?id=" + decodedVariantId + customAttributes
      } else {
        let decodedUpsellProductId = decodeId(upsellProduct.id)
        let customUpsellAttributes = utils.buildTrialPropertyString({
          tags: contentfulProduct.upsell.shopifyProduct.tags,
        })

        trackCartPixel(productTitle, active)
        trackCartPixel(upsellProductTitle, upsellProduct)

        window.location.href =
          CONSTANTS.CART_URL +
          "/add?id[]=" +
          `${decodedVariantId}` +
          customAttributes +
          `&id[]=${decodedUpsellProductId}` +
          customUpsellAttributes
      }
    }
  }

  return (
    <Layout>
      <SEO title={getShopifyProductTitle()} lang="en" />
      {contentfulProduct && contentfulProduct.sliderImages ? (
        <Slider {...sliderSettings}>
          {contentfulProduct.sliderImages.map(image => {
            let fluidImage = {
              base64: image.resize.base64,
              tracedSVG: image.resize.tracedSVG,
              aspectRatio: image.resize.aspectRatio,
              src: image.resize.src,
              width: image.resize.width,
              height: image.resize.height,
              srcSet: image.resize.src,
              srcWebp: image.fluid.srcWebp,
              sizes: image.fluid.sizes,
            }
            return (
              <Img
                key={image.contentful_id}
                fluid={fluidImage}
                alt={image.description}
                className="slider-image"
              />
            )
          })}
        </Slider>
      ) : null}

      <section id="pdp-content">
        <div className="pdp-header">
          {contentfulProduct && <h1>{contentfulProduct.heading}</h1>}
          {active && (
            <span className="bold">
              {storeInfo.currencySymbol || CONSTANTS.CURRENCY_SYMBOL}
              {Math.trunc(active.price)}
            </span>
          )}
        </div>
        {contentfulProduct && (
          <p className="large">{contentfulProduct.shortDescription}</p>
        )}
        {contentfulProduct && contentfulProduct.details ? (
          <ProductDetails
            prodDetails={contentfulProduct.details}
            upsell={false}
          />
        ) : null}
        {contentfulProduct && contentfulProduct.shopifyProduct ? (
          <div className="variant-wrapper">
            {contentfulProduct.shopifyProduct.variants.edges.map(
              (variant, index) => {
                return (
                  <VariantButton
                    variantSize={variant.node.title}
                    key={index}
                    available={variant.node.availableForSale}
                    active={active.id === variant.node.id}
                    handleClick={() => {
                      setActive(variant.node)
                    }}
                  />
                )
              }
            )}
          </div>
        ) : null}
        {contentfulProduct &&
        contentfulProduct.upsell &&
        contentfulProductColors ? (
          <UpsellProduct
            contentfulProduct={contentfulProduct}
            contentfulProductColors={contentfulProductColors}
            activeProductVariant={active}
            setActiveUpsell={upsellProduct}
            storeInfo={storeInfo}
            setUpsellProduct={setUpsellProduct}
            addUpsell={addUpsell}
            setAddUpsell={setAddUpsell}
          />
        ) : null}
      </section>

      <ShopifyButton
        activeProductVariant={active}
        handleClick={addProductToShopifyCart}
      >
        {storeInfo.tryNowText || CONSTANTS.TRY_NOW_BUTTON_TEXT}
      </ShopifyButton>
    </Layout>
  )
}

export default ProductTemplate
