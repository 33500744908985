import React from "react"
import "../styles/comforter-size-variant.scss"

function ComforterSizeVariant({ variantSize, active, available, handleClick }) {
  const variantClasses = ["variant-container"]
  if (active) {
    variantClasses.push("active")
  }
  if (!available) {
    variantClasses.push("sold-out")
  }
  return (
    <button
      tabIndex="0"
      onClick={handleClick}
      onKeyDown={handleClick}
      className={variantClasses.join(" ")}
    >
      {variantSize}
    </button>
  )
}

export default ComforterSizeVariant
