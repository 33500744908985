import React from "react"
import "../styles/pdp.scss"

function ShopifyButton({ children, activeProductVariant, handleClick }) {
  return (
    <button
      tabIndex="0"
      onClick={handleClick}
      onKeyDown={handleClick}
      className="pdp__submit-button"
    >
      {children}
    </button>
  )
}

export default ShopifyButton
