import React, { useState } from "react"
import "../styles/productDetails.scss"
import CloseIcon from "../images/svg/icon-x.svg"

function ProductDetails({ prodDetails, upsell }) {
  const [visible, setVisible] = useState('initial-state')
  const detailsClasses = ["product-details__wrapper"]

  const handleClick = () => {
    visible !== 'show' ? setVisible('show') : setVisible('hidden')
  }

  if (upsell) {
    detailsClasses.push('product-details__upsell')
  } else {
    detailsClasses.push('product-details__main')
  }

  return (
    <div className={detailsClasses.join(" ")}>
      <span className="product-details__title"
            role="button"
            onClick={handleClick}
            onKeyDown={handleClick}
      >
      Product Details
      </span>
      <div className={`product-details__container ${visible}`}>
        <div className="product-details__close"
             role="button"
             onClick={handleClick}
             onKeyDown={handleClick}
        >
        <CloseIcon />
        </div>
        <h1>Product Details</h1>
        <ul className="product-details__list">
          {prodDetails.map((item, index)=>{
            return (
              <li key={index}>{item}</li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}



export default ProductDetails
