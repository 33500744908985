const utils = {
  buildCustomAttributes: ({ tags }) => {
    return [
      {
        key: "trial_product",
        value: tags.includes("trial").toString(),
      },
    ]
  },
  buildTrialPropertyString: ({ tags }) => {
    return `&properties[trial_product]=${tags.includes("trial").toString()}`
  },
}

export default utils
