import React, { useState, useContext, useEffect, useCallback } from "react"
import StoreContext from "../context/StoreContext"
import "../styles/upsellProduct.scss"
import CONSTANTS from "../constants"
import CheckIcon from "../images/svg/check.svg"
import ProductDetails from "../components/productDetails"

// import utils from "../utils"

const defaultColorIndex = 0
const UpsellProduct = ({
  contentfulProduct,
  contentfulProductColors,
  activeProductVariant,
  storeInfo,
  setUpsellProduct,
  addUpsell,
  setAddUpsell,
}) => {
  let productUpsells = contentfulProduct.upsell.shopifyProduct
  const [activeColor, setActiveColor] = useState(
    contentfulProductColors[defaultColorIndex].node
  )
  const [activeVariant, setActiveVariant] = useState()
  const [upsellInCart, setUpsellInCart] = useState()
  // const [upsellBtnText, setUpsellBtnText] = useState('Add')

  const {
    store: { client, checkout },
    removeLineItem,
    // addVariantToCart,
  } = useContext(StoreContext)

  const getActiveVariant = useCallback(
    color => {
      // Get size from contentfulProduct, and color from contentfulProductColors
      // then combine them into a searchable string
      const defaultSize = activeProductVariant.title.split("/")[0]
      const defaultColor = color.shopifyColorOption.color
      const variantNameVersion1 = `${defaultSize} / ${defaultColor}`
      const variantNameVersion2 = `${activeProductVariant.title} / ${defaultColor}`

      const initVariant = productUpsells.variants.edges.find(v => {
        return (
          v.node.title === variantNameVersion1 ||
          v.node.title === variantNameVersion2
        )
      })
      return initVariant && initVariant.node
    },
    [activeProductVariant, productUpsells]
  )

  useEffect(() => {
    const newActiveVariant = getActiveVariant(activeColor)
    setActiveVariant(newActiveVariant)
    // Update upsell variant if upsell is checked
    if (newActiveVariant.availableForSale && addUpsell) {
      setUpsellProduct(newActiveVariant)
      setUpsellInCart(newActiveVariant)
    }
  }, [activeProductVariant, activeColor, getActiveVariant, addUpsell, setUpsellProduct])

  const handleClick = (e, color) => {
    setActiveColor(color.node)
  }

  const createSwatches = productUpsells => {
    let colors = contentfulProductColors.map((color, index) => {
      const swatchClasses = []
      const swatchInnerClasses = ["color-swatch-inner"]
      if (
        color.node.shopifyColorOption.id === activeColor.shopifyColorOption.id
      ) {
        swatchClasses.push("active")
      }
      // Get associated variant of this color
      const swatchVariant = getActiveVariant(color.node)
      if (!swatchVariant.availableForSale) {
        swatchClasses.push("sold-out")
        swatchInnerClasses.push("sold-out")
      }
      return (
        <button
          key={`color-${index}`}
          tabIndex="0"
          className={swatchClasses.join(" ")}
          onClick={e => handleClick(e, color)}
          onKeyDown={e => handleClick(e, color)}
          data-color={color.node.shopifyColorOption.color}
        >
          <div
            className={swatchInnerClasses.join(" ")}
            style={{ backgroundColor: `#${color.node.hexValue}` }}
          />
        </button>
      )
    })
    return colors
  }

  const removeUpsellCart = async () => {
    const removedItem = checkout.lineItems.find(lineItem => {
      return lineItem.variant.id === upsellInCart.id
    })
    if (removedItem) {
      await removeLineItem(client, checkout.id, removedItem.id)
    }
    return true
  }

  const handleUpsellCart = async e => {
    // e.preventDefault()
    const newAddUpsell = !addUpsell
    // skip if variant is not available
    if (activeVariant.availableForSale) {
      if (newAddUpsell) {
        // setUpsellBtnText("Adding!")
        // const customAttributes = utils.buildCustomAttributes({
        //   tags: contentfulProduct.shopifyProduct.tags,
        // })
        // await addVariantToCart(activeVariant.id, 1, customAttributes)
        setUpsellProduct(activeVariant)
        setUpsellInCart(activeVariant)
      } else {
        await removeUpsellCart()
        setUpsellInCart()
      }
      setAddUpsell(!addUpsell)
      // setTimeout(() => {
      //   setUpsellBtnText("Remove")
      // }, 800)
    }
  }

  return (
    <section id="upsell-content" className="upsell__content-wrapper">
      <div className="upsell__product-addon">
        <h3>Add-on</h3>
        <label className="upsell__check-wrapper">
          <input type="checkbox" onChange={handleUpsellCart} />
          <span></span>
          <CheckIcon />
        </label>
      </div>
      <div className="upsell__product-header">
        {contentfulProduct.upsell && (
          <h1>{contentfulProduct.upsell.heading}</h1>
        )}
        {activeVariant && (
          <span className="bold">
            {storeInfo.currencySymbol || CONSTANTS.CURRENCY_SYMBOL}
            {Math.trunc(activeVariant.price)}
          </span>
        )}
      </div>
      {contentfulProduct.upsell && contentfulProduct.upsell.details ? (
        <ProductDetails
          prodDetails={contentfulProduct.upsell.details}
          upsell={true}
        />
      ) : null}
      {activeColor ? (
        <div className="upsell__color-panel">
          <img
            src={activeColor.colorImage.fluid.src}
            alt={activeColor.colorImage.title || "color"}
            className="upsell__color-image"
          />
          <div className="upsell__color-picker">
            <h3>{activeColor.shopifyColorOption.color}</h3>
            <div className="upsell__swatch-wrapper">
              {createSwatches(productUpsells)}
            </div>
          </div>
          {/* <button
            className={`upsell__atc ${addUpsell ? "active" : ""} ${addUpsell && upsellBtnText.includes("Adding") ? "upsell__item-adding" : ""}`}
            onClick={handleUpsellCart}
          >
            {addUpsell ? upsellBtnText : "Add"}
          </button> */}
        </div>
      ) : null}
    </section>
  )
}

export default UpsellProduct
